@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "Avigea";

  src: url("../public/assets/fonts/Avigea.otf");
}

@font-face {
  font-family: "Roboto";
  src: url("../public/assets/fonts/Roboto-Regular.ttf");
}
@font-face{
  font-family: "Baumans";
  src: url("../public/assets/fonts/Baumans-Regular.ttf")
}
@font-face{
  font-family: "Inter";
  src: url("../public/assets/fonts/Inter-Regular.ttf")
}
html {
  height: 100%;
  
}

body {
  height: 100%;
 
}

.App {
  height: 100vh;
  background: #fff;
}
/* .splide__arrow {
  display: none !important;
} */




